@import "variables";
@import "fonts";

html {
  font: $base-font-size Roboto, sans-serif;
  color: $base-color;
  background-color: #e7f6f9;
  box-sizing: border-box;

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
}

br {
  font-family: Arial, sans-serif;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

h1, h2, h3, h4, ul {
  margin: 0;
}

ul {
  padding: 0;
  list-style: none;
}

button {
  padding: 0;
  background: none;
  border: none;
}
